import React from "react";
import {Link} from "react-router-dom";
import config from "../../config";
// import { prettyDOM } from "@testing-library/react";

/* global ym */

export default class Countries extends React.Component {

  componentDidMount() {
    this.props.showButton()
    this.props.stop()
  }

  render() {
    return (
      <div className="countries">
        <a className="countries__logo"
           href="https://www.mondelezinternational.com/"
           target="_blank"
           rel="noreferrer">
          <span className="v-hidden">Mondelez International</span>
        </a>
        {config.countries.map((country, key) => {
          const className = `countries__link countries__link_${country.code}`;
          if (country.isExternal) {
            return (
              <a
                key={key}
                className={className}
                href={country.url}
                onClick={() => {
                  ym(87314401, "reachGoal", country.code);
                  return true;
                }}
                rel="noopener noreferrer"
              >
                <span className="v-hidden">{country.label}</span>
              </a>
            );
            // target="_blank"
          } else {
            return (
              <Link
                key={key}
                className={className}
                onClick={() => {
                  ym(87314401, "reachGoal", country.code);
                  return true;
                }}
                to={{
                  pathname: country.url,
                }}
              >
                <span className="v-hidden">{country.label}</span>
              </Link>
            );
          }
        })}
      </div>
    );
  }
}

import React from "react";
import config from "../../config";

export default class Card extends React.Component {
  constructor(props) {
    super(props);

    const [country] = config.countries.filter((country) => {
      return country.url === props.location.pathname;
    });

    this.state = {
      country,
    };
  }

  componentDidMount() {
    document.documentElement.lang = this.state.country.code;

    if(this.state.country.card?.sound){
      this.props.showButton();
      this.props.play();
    } else {
      this.props.hideButton();
    }
  }

  render(){
    const CardButton = (props) => {
      return (
        <a className={`card__link card__link_${props.mod}`}
           href={props.href}
           target="_blank"
           rel="noreferrer">
          <span className="v-hidden">{props.label}</span>
        </a>
      );
    }

    return (
      <div className={`card card_${this.state.country.code}`}>
        <span className="v-hidden">{this.state.country.card.label}</span>
        {this.state.country.card?.button
          ? <CardButton
            mod={this.state.country.code}
            href={this.state.country.card.button.url}
            label={this.state.country.card.button.label}
          />
          : ''
        }
      </div>
    );
  }
}

const config = {
  countries: [
    {
      label: "Россия",
      code: "ru",
      // url: "/ru",
      url: "https://www.mondelezinternational.com/Russia",
      isExternal: true,
      // card: {
      //   sound: false,
      //   label: "Подарите близким праздник",
      //   button: {
      //     label: "Перейти на сайт",
      //     url: "https://www.mondelezinternational.com/Russia"
      //   },
      // },
    },
    {
      label: "Казахстан",
      code: "kz",
      url: "/kz",
      isExternal: false,
      card: {
        sound: true,
        label: "Нежность - лучший подарок",
      },
    },
    {
      label: "Україна",
      code: "ua",
      url: "https://milka-promo.com.ua/",
      isExternal: true,
    },
    {
      //Грузия
      label: "საქართველო",
      code: "ge",
      url: "/ge",
      isExternal: false,
      card: {
        sound: true,
        label: "სინაზე — საუკეთესო საჩუქარია",
      },
    },
    {
      //Азербайджан
      label: "Azərbaycan",
      code: "az",
      url: "/az",
      isExternal: false,
      card: {
        sound: true,
        label: "Zəriflik ən yaxşı hədiyyədir",
      },
    },
    {
      //Узбекистан
      label: "O'zbekiston",
      code: "uz",
      url: "/uz",
      isExternal: false,
      card: {
        sound: true,
        label: "Noziklik – eng yaxshi tuhfa",
      },
    },
    {
      //Монголия
      label: "Монгол",
      code: "mn",
      url: "/mn",
      isExternal: false,
      card: {
        sound: true,
        label: "Хайр энхрийлэл - хамгийн сайхан бэлэг",
      },
    },
    {
      //Кыргызстан
      label: "Кыргызстан",
      code: "kg",
      url: "/kg",
      isExternal: false,
      card: {
        sound: true,
        label: "Назиктик – эң жакшы белек",
      },
    },
    {
      //Армения
      label: "Հայաստան",
      code: "am",
      url: "/am",
      isExternal: false,
      card: {
        sound: true,
        label: "Քնքշությունը լավագույն նվերն է",
      },
    },
  ],
};

export default config;

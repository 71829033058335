import React from "react";
import {BrowserRouter, Switch, Route} from "react-router-dom";

import "./App.scss";

import Countries from "./components/Countries/Countries";
import Card from "./components/Card/Card";
import Sound from "./components/Sound/Sound";

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHidden: false,
      isMuted: false,
      canPlay: false,
    };

    this.toggleMuted = this.toggleMuted.bind(this);
    this.hideButton = this.hideButton.bind(this);
    this.showButton = this.showButton.bind(this);
    this.play = this.play.bind(this);
    this.stop = this.stop.bind(this);
  }

  toggleMuted() {
    this.setState({
      isMuted: !this.state.isMuted,
    });
  }

  hideButton() {
    this.setState({
      isHidden: true,
    });
  }

  showButton() {
    this.setState({
      isHidden: false,
    });
  }

  play() {
    this.setState({
      canPlay: true,
    });
  }

  stop() {
    this.setState({
      canPlay: false,
    });
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/"
                 render={(props) => (
                   <Countries {...props}
                              hideButton={this.hideButton}
                              showButton={this.showButton}
                              play={this.play}
                              stop={this.stop}
                   />
                 )}
          />
          <Route exact path="/:country"
                 render={(props) => (
                   <Card {...props}
                         hideButton={this.hideButton}
                         showButton={this.showButton}
                         play={this.play}
                         stop={this.stop}
                   />
                 )}
          />
        </Switch>
        <Sound
          playerId="player"
          canPlay={this.state.canPlay}
          isMuted={this.state.isMuted}
          onClick={this.toggleMuted}
          hidden={this.state.isHidden}
        />
      </BrowserRouter>
    );
  }
}

import React from "react";
// import config from "../../config";

export default class Sound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false
    }

    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  componentWillUnmount() {
    if (this.player) {
      try {
        this.player.pause();
      } catch (error) {
      }
      this.player.currentTime = 0;
    }
  }

  componentDidMount() {
    this.player = document.getElementById(this.props.playerId);
    this.player.pause();
    this.player.currentTime = 0;
    this.player.muted = this.props.isMuted;
  }

  componentDidUpdate() {
    this.player.muted = this.props.isMuted;

    if (this.props.canPlay) {
      this.play();
    } else {
      this.pause();
    }
  }

  async play() {
    if (!this.state.showPopup) {
      try {
        await this.player.play();
      } catch (error) {
        if (error.name === 'NotAllowedError') {
          this.showPopup();
        }
      }
    }
  }

  async pause() {
    try {
      await this.player.pause();
      this.player.currentTime = 0;
    } catch (error) {
    }
  }

  showPopup() {
    this.setState({
      showPopup: true
    });
  }

  hidePopup() {
    this.setState({
      showPopup: false
    });
  }

  render() {
    return (
      <div className="sound">
        <button
          className="sound__toggle"
          type="button"
          title={`${this.props.isMuted ? "Включить" : "Отключить"} звук`}
          aria-label={`${this.props.isMuted ? "Включить" : "Отключить"} звук`}
          aria-pressed={this.props.isMuted}
          onClick={this.props.onClick}
          hidden={this.props.hidden}
        />
        <div className="sound__popup" role="alert" hidden={!this.state.showPopup}>
          <div className="sound__alert">
            <span className="v-hidden">Включите звук</span>
            <button
              className="sound__button"
              type="button"
              title="Ok"
              aria-label="Ok"
              onClick={this.hidePopup}
            />
          </div>
        </div>
      </div>
    );
  }
}
